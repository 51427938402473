.signature {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 50px;
}

.full-line {
    width: 90%;
    max-width: 700px;
    height: 1px;
    border: 0;
    color: #999999;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
}

.support {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 5px;
    text-decoration: underline;
}

.support:hover {
    cursor: pointer;
}

.support span {
    color: white;
    font-size: 16px;
}

.company-info {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.company-info p {
    color: #999999;
    font-style: italic;
    font-size: 12px;
    margin: 5px;
}

.company-files span {
    color: #999999;
    font-style: italic;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: underline;
    font-size: 12px;
}

.company-files:hover {
    cursor: pointer;
}

.languages-change {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.languages-change span {
    font-size: 14px;
}

.languages-change button {
    cursor: pointer;
    font-size: 14px;
    color: white;
    border: solid 1px transparent;
    background-color: transparent;
    border-radius: 5px;
}

.languages-change button:hover {
    border-color: rgba(256, 256, 256, 0.3);
}

.languages-change button.active {
    border-color: rgba(256, 256, 256, 1);
}

@media (max-width: 800px) {
    .support span {
        font-size: 14px;
    }
}

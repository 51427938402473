.offer-section {
    min-height: 100vh;
    margin: 0;
    justify-content: start;
    gap: 70px;
}

.button-back-offer {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.button-back-offer.button-line-main {
    color: white;
    gap: 5px;
    border: solid 1px white ;
}

.button-back-offer.button-line-main:hover {
    background-color: white;
    color: #005CEF;
}

.offer-text-block {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    max-width: 900px;
    width: 90%;
    box-sizing: border-box;

    gap: 20px;
}

.offer-text-block h1 {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    font-weight: 900;
}

.offer-text-block p {
    text-align: justify;
    font-family: Roboto, serif;
    font-size: 0.85em;

    width: 100%;
}

.offer-text-block a {
    color: white;
}

.button-download-offer {
    height: 30px;
    border-width: 1px;
    margin: 20px 0 30px;
}

.button-download-offer span {
    font-size: 0.7em;
    font-weight: 600;
}

@media (max-width: 999px) {
    .offer-section {
        gap: 50px;
    }

    .button-back-offer {
    }

    .button-back-offer.button-line-main {
        gap: 2px;
    }

    .button-back-offer.button-line-main:hover {
    }

    .offer-text-block {
        gap: 15px;
    }

    .offer-text-block h1 {
    }

    .offer-text-block p {
        font-size: 1em;
    }

    .offer-text-block a {
    }

    .button-download-offer {
        height: 20px;
    }

    .button-download-offer span {
    }
}
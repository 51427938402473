@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

.App {
  text-align: center;
}

html, body {
  color: white;
  margin: 0;
  padding: 0;
  /*font-family: "Poiret One", sans-serif;*/
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18pt;
  scroll-behavior: smooth;
}

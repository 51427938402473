@media (max-width: 999px) {
    .user-console-page {
        font-size: 16px;
    }

    .background-top-user-console {
        /*width: 100%;*/
        /*height: 500px;*/
        /*inset: -200px 0 auto;*/
        /*z-index: 0;*/
    }

    .gradient-top-user-console, .noise-top-user-console {
    }

    .background-bottom-user-console {
        /*width: 100%;*/
        /*height: 500px;*/
        /*inset: auto 0 0;*/
        /*z-index: 0;*/
    }

    .gradient-bottom-user-console, .noise-bottom-user-console {
    }

    .user-console-content-main {
        max-width: 450px;
        gap: 100px;
    }

    .account-info-main {
        justify-content: center;
    }

    .user-console-main {
        gap: 20px;
    }

    .profile-block-main {
        border-radius: 25px;
    }

    .title-profile-block-main {
    }

    .title-profile-block-main hr {
        width: calc(100% - 50px);
    }

    .group-elements-title-profile-main {
        padding: 5px;
    }

    .wrapper-title-main {
        height: 40px;
    }

    .group-elements-title-profile-main h1 {
    }

    .group-elements-title-profile-main:has(> *:nth-child(n+2)) {
    }

    .group-elements-title-profile-main:has(> *:nth-child(n+2)) h1 {
    }

    .group-elements-title-profile-main:has(.status-title-profile-main) {
        padding: 10px;
    }

    .group-elements-title-profile-main:has(.buttons-line-support-block) {
        padding: 15px 5px 5px 15px;
    }

    .group-elements-title-profile-main:has(> h1:first-child) {
        padding-left: 25px;
    }

    .status-title-profile-main {
        height: 30px;
        border-radius: 15px;
        padding: 0 15px;
    }

    .content-profile-block-main {
        padding: 5px;
    }

    .content-profile-block-main h1{
    }

    .button-user-console-main {
        height: 40px;
        border-radius: 20px;
        padding: 0 25px;
        /*gap: 25px;*/
    }

    .button-user-console-main:hover {
    }

    .button-user-console-main:active {
    }

    /*Для кнопок с прогресс баром---*/
    .button-progress-user-console-main {
    }

    .button-text-container {
    }

    .button-text-main {
    }

    .hold-text {
    }

    .fade-main {
    }

    .fade-up {
    }

    .fade-down {
    }

    .red-point-console-main {
        width: 12px;
        height: 12px;
    }


    /*Плашка профиля -----------------------------------------------------*/
    .account-info-block {
        height: 80px;
        border-radius: 40px;
        padding: 0 10px 0 40px;
        gap: 15px;
    }

    .account-info-block img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }

    .info-list-account-info {
        font-size: 0.9em;
        gap: 3px;
    }

    .info-list-account-info div {
    }

    .info-list-account-info .red-point-console-main {
        width: 8px;
        height: 8px;
    }

    .info-list-account-info button {
        font-size: 0.7em;
    }

    .info-list-account-info button:hover {
    }

    .info-list-account-info button:active {
    }

    /*Блок реферальной программы-----------------------------------------------------*/
    .referral-block-user-console {
    }

    .signature-referral {
        height: 40px;
        border-radius: 20px;
        padding: 0 30px;
    }

    .signature-referral h1 {
    }


    .signature-referral .red-point-console-main {
        inset: 0 auto 0 14px;
    }

    /*Продажный блок-----------------------------------------------------*/
    .sale-block-user-console {
    }

    .sale-block-user-console button {
    }

    .content-block-sale-block {
        gap: 20px;
    }

    .content-section-sale-block {
    }

    .content-section-sale-block, .content-section-row-sale-block {
        padding: 0 20px;
    }

    .content-section-row-sale-block {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .cost-description-sale-block {
        padding: 5px 10px;
        margin: 0 0 0 -10px;

        border-radius: 20px;
    }

    .cost-description-sale-block .cost {
    }

    .cost-benefits-sale-block {
        gap: 15px;
    }

    .promo-form-sale-block {
        width: 100%;
    }

    .promo-input-sale-block {
        height: 40px;
        border-radius: 20px;
        padding: 0 130px 0 15px;
        font-size: 1em;
    }

    .promo-button-sale-block {
        inset: 0 5px 0 auto;
        width: 120px !important;
        height: 30px;
        padding: 0 15px;
    }

    .promo-sign-sale-block {
        inset: auto 0 -20px;
        height: 20px;
        font-size: 0.8em;
    }

    /*Подписка отменена----------------------------------------------------*/
    .cancelled-description-user-console {
        gap: 15px;

    }

    .cancelled-description-user-console .status-title-profile-main {
    }

    .cancelled-description-user-console button {
        width: 100%;
    }

    .content-block-cancelled-description {
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 20px;
    }

    .cost-description-cancelled-description {
        width: 100%;
        padding: 5px 20px;
        box-sizing: border-box;
        margin: 0;

        border-radius: 20px;
    }

    .cost-description-cancelled-description h1 {
    }

    .cost-description-cancelled-description .cost {
    }

    /*Подписка активна----------------------------------------------------*/
    .active-description-user-console {
    }

    .active-description-user-console .status-title-profile-main {
    }

    .content-block-active-description {
        align-items: start;
        padding: 15px 25px;
        gap: 15px;
    }

    .first-pay-active-description {
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }

    .first-pay-active-description .status-title-profile-main {
        padding: 0 12px;
        transform: translate(-12px, 0);
    }

    .cancel-description-active-description {
        height: 20px;
        border-radius: 10px;
        padding: 0 10px;
        transform: translate(-10px, 0);
    }

    .cancel-description-active-description:hover {
    }

    .cancel-description-active-description:active {
    }

    /*Блок с устройствами----------------------------------------------------*/
    .devices-user-console {
    }

    .devices-user-console .status-title-profile-main {
    }

    .content-block-devices {
        padding: 20px 5px 5px;
        gap: 30px;
    }

    .content-block-devices:empty {
    }

    .device-unit-devices {
        height: auto;
        border-radius: 20px;
    }

    .device-logo-devices {
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }

    .device-logo-devices img {
        width: 60%;
    }

    .device-info-devices {

        width: auto;
        height: 40px;
        border-radius: 20px;
        padding: 0 15px 0 10px;
    }

    .name-data-string-devices{
    }

    .title-device-devices {
    }

    .text-device-devices {
    }

    .device-detach-devices {
        width: calc(100% - 50px);
        height: 40px;
        border-radius: 20px;
        margin-left: auto;
    }

    .button-detach-devices {
        width: 100%;
        height: 100%;
    }

    .separator-devices {
        display: none;
    }

    /*Скачать приложение----------------------------------------------------*/
    .product-app-user-console {
    }

    .content-block-product-app {
        padding: 20px 5px 5px;
    }

    .buttons-line-product-app {
        flex-direction: column;
    }

    .button-download-app-product-app {
        flex-grow: 0;
        width: 230px;
    }

    .button-download-app-product-app img {
    }

    .red-point-console-product-app {
        inset: 0 14px 0 auto;
    }

    .marker-product-app {
        justify-content: center;

        height: 40px;
        width: 100%;
        padding: 0 0 0 9px;
        gap: 10px;
        border-radius: 20px;
    }

    .marker-product-app.highlight {
    }

    /*Поддержка----------------------------------------------------*/
    .support-block-user-console {
    }

    .buttons-line-support-block {
        padding: 0;
        gap: 10px;
        margin-left: auto;
    }

    .button-support-support-block {
        padding: 0 20px;
        border-radius: 20px;
    }

    /*Отмена подписки----------------------------------------------------*/
    .cancel-description-user-console {

    }

    .content-block-cancel-description {
        padding: 30px 30px;
        gap: 30px;
    }

    .text-condolences-cancel-description {
        /*text-align: center;*/
    }

    .cancel-button-cancel-description {
        height: 46px;
        padding: 0 50px;
        border-radius: 25px;
    }

}
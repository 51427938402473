h1, h2, h3, h4, h5, h6, p{
    margin: 0;
    padding: 0;
}

button {
    font-family: Montserrat, serif;
    cursor: pointer;
}

.page-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-size: 24px;
}

/*Основные настройки типовых элементов -----------------------------------------------------*/
.section-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    margin: -2px 0 0; /*это что бы не было вижно стыка между блоками*/

    box-sizing: border-box;
}

.background-main {
    position: absolute;
    inset: 0;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    z-index: -1;
}

.gradient-main, .noise-main {
    position: absolute;
    inset: 0;
    margin: auto;

    width: 100%;
    height: 100%;
}

.gradient-main {
    background-color: #005CEF;
}

.noise-main {
    background-image: url('../Imagines/Noise.PNG');
    background-size: 400px auto;
    background-repeat: repeat;
    background-position: center;
    opacity: 0.6;

    /*mix-blend-mode: overlay;*/
}

.red-point-main {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #E40000;
}

.button-line-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 46px;
    background-color: transparent;
    color: white;

    border: solid 2px white;
    border-radius: 23px;
    padding: 0 20px;

    font-family: Montserrat, serif;
    font-size: 0.75em;
    font-weight: 500;
}

.button-line-main:hover {
    color: #005CEF;
    background-color: white;
}

.button-line-main:active {
    color: #005CEF;
    background-color: white;
}

/*Секция заглавия сайта -----------------------------------------------------*/
.title-site {
    height: 100vh;
    overflow-x: hidden;
}

.background-title-site {
    width: min(100vh , 100vw);
    height: min(100vh , 100vw);
    left: 50%;
    transform: translateX(-50%);
}

.gradient-title-site, .noise-title-site {
    mask: radial-gradient(
            closest-side,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.99) 5%,
            rgba(0, 0, 0, 0.97) 10%,
            rgba(0, 0, 0, 0.94) 15%,
            rgba(0, 0, 0, 0.90) 20%,
            rgba(0, 0, 0, 0.85) 25%,
            rgba(0, 0, 0, 0.79) 30%,
            rgba(0, 0, 0, 0.73) 35%,
            rgba(0, 0, 0, 0.65) 40%,
            rgba(0, 0, 0, 0.58) 45%,
            rgba(0, 0, 0, 0.50) 50%,
            rgba(0, 0, 0, 0.42) 55%,
            rgba(0, 0, 0, 0.35) 60%,
            rgba(0, 0, 0, 0.27) 65%,
            rgba(0, 0, 0, 0.21) 70%,
            rgba(0, 0, 0, 0.15) 75%,
            rgba(0, 0, 0, 0.10) 80%,
            rgba(0, 0, 0, 0.06) 85%,
            rgba(0, 0, 0, 0.03) 90%,
            rgba(0, 0, 0, 0.01) 95%,
            rgba(0, 0, 0, 0) 100%
    );
    mask-size: cover;
    mask-repeat: no-repeat;
}

.header-buttons {
    position: absolute;
    inset: 0 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: start;

    width: 100%;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
    gap: 15px;
}

.button-enter-title-site {
    height: 36px;
    border-radius: 19px;
    font-size: 0.7em;
    color: #005CEF;
    padding: 0 15px;
    gap: 5px;
    border: solid 1px #005CEF;
    box-sizing: content-box;
}

.button-enter-title-site:hover {
    color: white;
    background-color: #005CEF;
}

.language-switcher-title-site {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    border: solid 1px #005CEF;
    border-radius: 19px;

    max-height: 36px;
    overflow: hidden;

    transition: max-height 0.2s ease-out, opacity 0.2s ease-out;
}

.language-switcher-title-site.active {
    max-height: calc(36px * 4);
}

.button-languages-title-site {
    width: 32px;
    min-height: 32px;
    height: 32px;

    margin: 2px;

    font-size: 0.7em;
    color: #005CEF;

    padding: 0;
    border: none;
}

.button-languages-title-site.collapsed {
    width: 36px;
    min-height: 36px;
    height: 36px;

    margin: 0;
}

.button-languages-title-site:hover {
    color: white;
    background-color: #005CEF;
}

.title-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-string {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
}


.title {
    position: relative;

    font-size: 2.7em;
    font-weight: 900;

    line-height: 0.68;
}

.sign {
    position: absolute;
    inset: 0 auto auto calc(100% + 10px);

    font-size: 0.7em;
    font-weight: 900;
    white-space: nowrap;

    line-height: 0.68;
}

.red-point-title {
    width: 12px;
    height: 12px;

    position: absolute;
    inset: auto auto -1px calc(100% + 16px);
}

.manifest {
    font-size: 1.15em;
    font-weight: 900;

    margin-top: 20px;
}

.button-to-prise-line-main {
    margin-top: 40px;
    gap: 10px;
}

.chevron-icon {
    margin-top: 5px;
    margin-right: -7px;
    font-size: 1.85em;
}

/*Секция описания -----------------------------------------------------*/
.product-description-block {
    height: auto;
}

.background-product-description {
    position: sticky;
    inset: 0 auto auto;

    width: 100%;
    height: 120vh;
}

.gradient-product-description, .noise-product-description {
    height: 60vh;
    inset: auto 0 0;
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.99) 5%,
            rgba(0, 0, 0, 0.97) 10%,
            rgba(0, 0, 0, 0.94) 15%,
            rgba(0, 0, 0, 0.90) 20%,
            rgba(0, 0, 0, 0.85) 25%,
            rgba(0, 0, 0, 0.79) 30%,
            rgba(0, 0, 0, 0.73) 35%,
            rgba(0, 0, 0, 0.65) 40%,
            rgba(0, 0, 0, 0.58) 45%,
            rgba(0, 0, 0, 0.50) 50%,
            rgba(0, 0, 0, 0.42) 55%,
            rgba(0, 0, 0, 0.35) 60%,
            rgba(0, 0, 0, 0.27) 65%,
            rgba(0, 0, 0, 0.21) 70%,
            rgba(0, 0, 0, 0.15) 75%,
            rgba(0, 0, 0, 0.10) 80%,
            rgba(0, 0, 0, 0.06) 85%,
            rgba(0, 0, 0, 0.03) 90%,
            rgba(0, 0, 0, 0.01) 95%,
            rgba(0, 0, 0, 0) 100%
    );
    mask-size: cover;
    mask-repeat: no-repeat;
}

.product-description-content {
    position: relative;
    inset: -80vh 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    height: 100%;

    gap: 38vh;
}

.product-description-element {
    width: min(90%, 700px);
    color: #005AE6;
}

.title-description-element {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: baseline;
}

.red-point-description-element {

}

.title-text-description-element {
    font-size: 1.5em;
    font-weight: 900;
    margin-left: 50px;
}

.description-element-text {
    font-size: 0.85em;
    font-weight: 900;
    margin: 45px 0 0;
}

/*Секция с информацией -----------------------------------------------------*/
.product-info-section {
    height: auto;
    gap: 200px;
}

.background-product-info {
    width: 100%;
    height: 100%;
}

.gradient-product-info, .noise-product-info {
}

/* Цены ---------------------------*/
.content-product-prise {
    width: 650px;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    gap: 40px;

    padding: 10px 0 0 0;

    /*border: solid 1px red;*/
}

.title-cost-string-product-prise {
    position: relative;

    display: flex;
    justify-content: start;
    align-items: center;

    width: 100%;
    /*height: 200px;*/

    /*border: solid 1px red;*/
}

.title-title-cost-string {
    position: relative;

    font-size: 2.3em;
    font-weight: 900;

    line-height: 0.68;

    /*border: solid 1px red;*/
}

.sign-title-cost-string {
    position: absolute;
    inset: 7px 0 auto auto;

    font-size: 0.85em;
    font-weight: 900;
    white-space: nowrap;

    line-height: 0.68;
}

.payments-list-product-prise {
    width: 100%;
    border-collapse: collapse;
}

.payments-list-product-prise td {
    vertical-align: baseline;
}

.sign-payments-list {
    font-size: 0.85em;
    font-weight: 600;
    white-space: nowrap;
}

.price-payments-list {
    font-size: 1.5em;
    font-weight: 900;
    white-space: nowrap;
}

.payments-list-product-prise td:nth-child(1),
.payments-list-product-prise td:nth-child(3),
.payments-list-product-prise td:nth-child(4) {
    width: 1%;
}

.payments-list-product-prise td:nth-child(2) {
    width: auto;
}

.benefit-list-product-prise {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.benefit-list-product-prise h1 {
    font-size: 1.5em;
    font-weight: 900;
}

.benefit-list-product-prise span {
    position: relative;
    font-size: 1em;
    font-weight: 600;
}

.benefit-list-product-prise span span {
    position: absolute;
    left: -50px;
}

.button-buy-product-prise {
    width: 100%;
    height: 60px;
    border-radius: 30px;

    font-size: 1em;
    font-weight: 800;
}

/* Приложение ---------------------------*/
.content-product-app {
    width: 900px;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    gap: 30px;

    /*border: solid 1px red;*/
}

.title-product-app {
    font-size: 1.5em;
    font-weight: 900;
}

.buttons-download-product-app {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.button-product-app {
    position: relative;
    height: 60px;
    border-radius: 30px;
    color: white;
    flex-grow: 1;
}

.button-product-app:hover {
    color: #005AE6;
}

.button-product-app svg {
    max-width: calc(100% - 60px);
    max-height: 70%;
}

.red-point-product-app {
    position: absolute;
    inset: 0 22.5px 0 auto;
    margin: auto;
}

.sign-marker-product-app {
    height: 30px;
    width: calc(100% + 30px);
    padding: 0 15px;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 30px;

    font-size: 0.85em;
    font-weight: 600;

    box-sizing: border-box;
    border-radius: 15px;
    border: solid 2px transparent;

    transition: border 0.3s ease-in-out;
}

.sign-marker-product-app.highlight {
    border: solid 2px #E40000;
}

/* Поддержка ---------------------------*/
.content-support {
    width: 900px;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    gap: 30px;

    /*border: solid 1px red;*/
}

.title-support {
    font-size: 1.5em;
    font-weight: 900;
}

.button-block-support {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;

    flex-wrap: wrap;
}

.sign-support {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 5px;
}

.sign-support h1 {
    font-size: 1em;
    font-weight: 900;
}

.sign-support span {
    font-size: 0.85em;
    font-weight: 600;
}

.button-support {
    height: 60px;
    border-radius: 30px;
    font-size: 1em;
    font-weight: 500;
    flex-grow: 1;
}

/*Футер -----------------------------------------------------*/
.footer-section {
    height: auto;
    padding-top: 200px;
}

.background-footer {
    width: 100%;
    height: 100%;
}

.gradient-footer, .noise-footer {
}

.footer-section hr {
    width: 900px;
    height: 1px;
    border: 0;
    background-color: white;
}

.content-footer {
    width: 900px;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 20px 0 30px;
    gap: 15px;
}

.content-footer span {
    font-size: 0.7em;
    font-weight: 600;
}

.button-footer {
    height: 30px;
    border-width: 1px;
}

.button-footer span {
    font-size: 0.7em;
    font-weight: 600;
}
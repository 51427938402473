.container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 30px;

    /*border: solid 1px red;*/
}

.text-block {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;

    /*border: solid 1px red;*/
}

.text-info {
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin: 0;

    /*border: solid 1px red;*/
}

.text-lightgray {
    color: lightgray;
    font-style: italic;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    margin: 0;

    /*border: solid 1px red;*/
}

.profile-picture {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 50%;
    margin: 0 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile-image {
    width: 90%;
    height: 90%;
    border-radius: 50%;
}


@media (max-width: 800px) {
    .container {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .text-block {
        text-align: center;
        height: 70px;
    }

    .profile-picture {
        margin: 0 0 20px 0;
    }
}
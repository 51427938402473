.RegistrationButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-width: 230px;
    height: 50px;
    border-radius: 25px;
    border: none;
    padding: 0;
    gap: 10px;
}

.RegistrationButton:hover {
    cursor: pointer;
}

.RegistrationBlock_logo {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: transparent;
}
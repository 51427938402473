.login-section {
    height: 100vh;
    margin: 0;
}

.button-back-main-page {
    color: white;
    gap: 5px;
    border: solid 1px white ;
}

.button-back-main-page:hover {
    background-color: white;
    color: #005CEF;
}

.login-block {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 900px;
    padding: 60px 30px;
    box-sizing: border-box;

    gap: 50px;
}

.title-login-block {
    text-align: center;
    font-size: 1.5em;
    font-weight: 900;
}

.button-block-login {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;

    gap: 30px;
}

@media (max-width: 999px) {
    .login-section {
    }

    .button-back-main-page {
        gap: 2px;
    }

    .button-back-main-page:hover {
    }

    .login-block {
        width: min(95%, 450px);
        padding: 0;
        gap: 30px;
    }

    .title-login-block {
    }

    .button-block-login {
        gap: 15px;
    }
}
@media (max-width: 999px) {
    .page-container {
        font-size: 14px;
    }

    /*Основные настройки типовых элементов -----------------------------------------------------*/
    .section-main {
    }

    .background-main {
    }

    .gradient-main, .noise-main {
    }

    .gradient-main {
    }

    .noise-main {
    }

    .red-point-main {
        width: 8px;
        height: 8px;
    }

    .button-line-main {
        height: 32px;
        border-radius: 16px;
        padding: 0 15px;
        border-width: 1px;
        font-size: 0.85em;
    }

    .button-line-main:hover {
    }

    .button-line-main:active {
    }

    /*todo дописать минимальные значения секций для экстремальных случаев*/

    /*Секция заглавия сайта -----------------------------------------------------*/
    .title-site {
    }

    .background-title-site {
        width: max(100vh , 100vw);
        height: max(100vh , 100vw);
    }

    .gradient-title-site, .noise-title-site {
    }

    .header-buttons {
        padding: 10px;
        gap: 10px;
    }

    .button-enter-title-site {
        height: 30px;
        border-radius: 16px;
        font-size: 0.85em;
        padding: 0 15px;
    }

    .button-enter-title-site:hover {
    }

    .language-switcher-title-site {
        border-radius: 16px;
        max-height: 30px;
    }

    .language-switcher-title-site.active {
        max-height: calc(30px * 4);
    }

    .button-languages-title-site {
        width: 26px;
        min-height: 26px;
        height: 26px;
        font-size: 0.85em;
        padding: 0;
    }

    .button-languages-title-site.collapsed {
        width: 30px;
        min-height: 30px;
        height: 30px;
    }

    .button-languages-title-site:hover {
    }

    .title-group {
    }

    .title-string {
    }


    .title {
    }

    .sign {
        font-size: 0.85em;
    }

    .red-point-title {
        width: 8px;
        height: 8px;

    }

    .manifest {
        font-size: 1.25em;
        margin-top: 15px;
    }

    .button-to-prise-line-main {
        margin-top: 25px;
        gap: 5px;
    }

    .chevron-icon {
        margin-top: 3px;
        font-size: 1.75em;
    }

    /*Секция описания -----------------------------------------------------*/
    .product-description-block {
    }

    .background-product-description {
    }

    .gradient-product-description, .noise-product-description {
    }

    .product-description-content {
    }

    .product-description-element {
        width: min(90%, 450px);
    }

    .title-description-element {
    }

    .red-point-description-element {
    }

    .title-text-description-element {
        margin-left: 25px;
    }

    .description-element-text {
        font-size: 1em;
        margin: 25px 0 0;
    }

    /*Секция с информацией -----------------------------------------------------*/
    .product-info-section {
        gap: 80px;
    }

    .background-product-info {
    }

    .gradient-product-info, .noise-product-info {
    }

    /* Цены ---------------------------*/
    .content-product-prise {
        width: min(85%, 400px);
        gap: 20px;
    }

    .title-cost-string-product-prise {
    }

    .title-title-cost-string {
        font-size: 2em;
    }

    .sign-title-cost-string {
        inset: 2px 0 auto auto;
    }

    .payments-list-product-prise {
    }

    .payments-list-product-prise td {
    }

    .sign-payments-list {
        font-weight: 600;
    }

    .price-payments-list {
        font-size: 1.2em;
    }

    .payments-list-product-prise td:nth-child(1),
    .payments-list-product-prise td:nth-child(3),
    .payments-list-product-prise td:nth-child(4) {
    }

    .payments-list-product-prise td:nth-child(2) {
    }

    .benefit-list-product-prise {
        gap: 15px
    }

    .benefit-list-product-prise h1 {
        font-size: 1.2em;
    }

    .benefit-list-product-prise span {
        font-size: 0.85em;
    }

    .benefit-list-product-prise span span {
        left: -20px;
    }

    .button-buy-product-prise {
        height: 36px;
        border-radius: 18px;
        font-weight: 800;
    }

    /* Приложение ---------------------------*/
    .content-product-app {
        width: min(85%, 400px);
        gap: 15px;
    }

    .title-product-app {
    }

    .buttons-download-product-app {
        flex-direction: column;
        gap: 10px;
    }

    .button-product-app {
        height: 40px;
        border-radius: 20px;
        flex-grow: 0;
        width: max(70%, 200px);
    }

    .button-product-app:hover {
    }

    .button-product-app svg {
        max-height: 50%;
    }

    .red-point-product-app {
        inset: 0 16px 0 auto;
    }


    .sign-marker-product-app {
        width: calc(100%);
        padding: 0 10px;
        justify-content: center;
        gap: 15px;
        border-width: 1px;
    }

    .sign-marker-product-app.highlight {
        border-width: 1px;
    }

    /* Поддержка ---------------------------*/
    .content-support {
        width: min(85%, 400px);
        gap: 15px;
    }

    .title-support {
    }

    .button-block-support {
        gap: 10px;
        row-gap: 15px;

    }

    .sign-support {
        width: 100%;
    }

    .sign-support h1 {
    }

    .sign-support span {
    }

    .button-support {
        height: 40px;
        border-radius: 20px;
        font-size: 1em;
    }

    /*Футер -----------------------------------------------------*/
    .footer-section {
        padding-top: 100px;
    }

    .background-footer {
    }

    .gradient-footer, .noise-footer {
    }

    .footer-section hr {
        width: min(85%, 400px);
    }

    .content-footer {
        width: min(85%, 400px);
        padding: 5px 0 10px;
        gap: 10px;
    }

    .content-footer span {
        font-size: 0.7em;
    }

    .button-footer {
        height: 20px;
    }

    .button-footer span {
    }
}
.panel-container {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-weight: 500;
    font-size: 12pt;
}

.panel-block {
    width: 96%;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: #CACBD1;
    color: #363636;
    text-align: center;
}

.panel-block:not(:last-child) {
    margin-bottom: 15px;
}

.panel-block-title {
    font-weight: 700;
    font-size: 12pt;
    margin: 0 0 10px;
}

.panel-block-comment {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 7px;
    border: solid 1.5px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 10pt;
    margin: 0 0 10px;
}

.panel-block-comment-accent {
    padding: 0 5px;
}

.panel-block-comment-NB {
    border-color: rgba(178, 34, 34, 0.5);
    background-color: rgba(178, 34, 34, 0.2);
    color: rgba(178, 34, 34, 1);
}

.panel-block-comment-NB-sh {
    background-color: transparent;
    box-shadow:
            0 0 100px rgba(178, 34, 34, 1),
            0 0 150px rgba(178, 34, 34, 1),
            0 0 200px rgba(178, 34, 34, 1),
            0 0 250px rgba(178, 34, 34, 1);
}

.panel-block-comment-So-So {
    border-color: rgba(210, 105, 30, 0.5);
    background-color: rgba(210, 105, 30, 0.2);
    color: rgba(210, 105, 30, 1);
}

.panel-block-comment-So-So-sh {
    background-color: transparent;
    box-shadow:
            0 0 100px rgba(210, 105, 30, 1),
            0 0 150px rgba(210, 105, 30, 1),
            0 0 200px rgba(210, 105, 30, 1),
            0 0 250px rgba(210, 105, 30, 1);
}

.panel-block-comment-OK {
    border-color: rgba(0, 100, 0, 0.5);
    background-color: rgba(0, 100, 0, 0.2);
    color: rgba(0, 100, 0, 1);
}

.panel-block-comment-OK-sh {
    background-color: transparent;
    box-shadow:
            0 0 100px rgba(0, 100, 0, 1),
            0 0 150px rgba(0, 100, 0, 1),
            0 0 200px rgba(0, 100, 0, 1),
            0 0 250px rgba(0, 100, 0, 1);
}

.panel-section {
    width: 100%;
    background-color: #EFF0F5;
    border-radius: 10px;
    padding: 10px 15px;
    box-sizing: border-box;
    overflow: hidden;
}

.panel-section:not(:last-child) {
    margin-bottom: 10px;
}

.panel-block-list {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
    padding: 10px 15px;
    box-sizing: border-box;
    border: solid 1.5px #FFFFFF;
    border-radius: 7px;
    background-color: #EFF0F5;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    transition: background-color 0.2s ease;
}
.panel-block-list:not(:last-child) {
    margin-bottom: 10px;
}
.panel-block-list:hover {
    background-color: #FFFFFF;
}
.panel-block-list:active {
    background-color: #FFFFFF;
}

.panel-block input {
    max-width: 100%;
    min-width: 70px;
    overflow: hidden;
    margin: 5px;
    height: 32px;
    background-color: #FFFFFF;
    color: #363636;
    border: 0 solid #ADAEB6;
    padding: 3px 3px 3px 10px;
    box-sizing: border-box;

    border-radius: 10px;
    font-size: 16px;
    flex-grow: 1;
}

.panel-block select {
    overflow: hidden;
    margin: 5px;
    height: 32px;
    background-color: #FFFFFF;
    color: #363636;
    border: 1px solid #ADAEB6;
    padding: 3px;

    border-radius: 10px;
    font-size: 16px;
}

.panel-button {
    width: auto;
    height: auto;
    min-height: 30px;
    margin: 5px;
    align-items: center;
    background-color: white;
    padding: 2px 8px;
    box-sizing: border-box;
    color: #363636;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.2s;
    border: solid 1px rgba(0, 0, 0, 0.7);
    flex-grow: 1;
}
.panel-button:hover {
    background-color: #EFF0F5;
}
.panel-button:active:not(:disabled) {
    background-color: black;
    color: white;
}
.panel-button-for-block{
    margin: 0;
}

.panel-button-flex-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    gap: 10px;
}

.panel-text-tips {
    font-style: italic;
    color: gray;
    font-size: 8pt;
}

table {
    border-collapse: separate;
    border-spacing: 5px 10px;
    width: 100%;
    margin: 10px 0;
}

td, tr {
    text-align: left;
    /*border: solid 1px red;*/
    padding: 0;
    margin: 0;
}




.navigation-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 36px;
    width: 120px;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 18px;
    background-color: #EFF0F5;

    /*border: solid 1px red;*/
}

.navigation-list button {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    /*border: solid 1px red;*/

}

.navigation-list button:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.user-console-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    font-size: 20px;

    width: 100%;
    min-height: 100vh;
    background-color: #D6DDE9;
    /*z-index: -1;*/
}

.background-top-user-console {
    width: 100%;
    height: 500px;
    inset: -100px 0 auto;
    z-index: 0;
}

.gradient-top-user-console, .noise-top-user-console {
    mask: linear-gradient(to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.99) 5%,
    rgba(0, 0, 0, 0.97) 10%,
    rgba(0, 0, 0, 0.94) 15%,
    rgba(0, 0, 0, 0.90) 20%,
    rgba(0, 0, 0, 0.85) 25%,
    rgba(0, 0, 0, 0.79) 30%,
    rgba(0, 0, 0, 0.73) 35%,
    rgba(0, 0, 0, 0.65) 40%,
    rgba(0, 0, 0, 0.58) 45%,
    rgba(0, 0, 0, 0.50) 50%,
    rgba(0, 0, 0, 0.42) 55%,
    rgba(0, 0, 0, 0.35) 60%,
    rgba(0, 0, 0, 0.27) 65%,
    rgba(0, 0, 0, 0.21) 70%,
    rgba(0, 0, 0, 0.15) 75%,
    rgba(0, 0, 0, 0.10) 80%,
    rgba(0, 0, 0, 0.06) 85%,
    rgba(0, 0, 0, 0.03) 90%,
    rgba(0, 0, 0, 0.01) 95%,
    rgba(0, 0, 0, 0) 100%
    );
    mask-size: cover;
    mask-repeat: no-repeat;
}

.background-bottom-user-console {
    width: 100%;
    height: 500px;
    inset: auto 0 0;
    z-index: 0;
}

.gradient-bottom-user-console, .noise-bottom-user-console {
    mask: linear-gradient(to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.99) 5%,
    rgba(0, 0, 0, 0.97) 10%,
    rgba(0, 0, 0, 0.94) 15%,
    rgba(0, 0, 0, 0.90) 20%,
    rgba(0, 0, 0, 0.85) 25%,
    rgba(0, 0, 0, 0.79) 30%,
    rgba(0, 0, 0, 0.73) 35%,
    rgba(0, 0, 0, 0.65) 40%,
    rgba(0, 0, 0, 0.58) 45%,
    rgba(0, 0, 0, 0.50) 50%,
    rgba(0, 0, 0, 0.42) 55%,
    rgba(0, 0, 0, 0.35) 60%,
    rgba(0, 0, 0, 0.27) 65%,
    rgba(0, 0, 0, 0.21) 70%,
    rgba(0, 0, 0, 0.15) 75%,
    rgba(0, 0, 0, 0.10) 80%,
    rgba(0, 0, 0, 0.06) 85%,
    rgba(0, 0, 0, 0.03) 90%,
    rgba(0, 0, 0, 0.01) 95%,
    rgba(0, 0, 0, 0) 100%
    );
    mask-size: cover;
    mask-repeat: no-repeat;
}

.user-console-content-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 96%;
    max-width: 850px;
    padding: 50px 0;
    color: #242F40;
    gap: 80px;
    z-index: 1;
}

.account-info-main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
}

.user-console-main {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 40px;
    width: 100%;
}

.profile-block-main {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    border-radius: 45px;
    background-color: rgba(255, 255, 255, 0.7);
    border: solid 1px white;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.title-profile-block-main {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    height: auto;
}

.title-profile-block-main hr {
    width: calc(100% - 90px);
    height: 1px;
    border: 0;
    background-color: rgba(36, 47, 64, 1);
    margin: 0 auto;
}

.group-elements-title-profile-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    /*height: 90px;*/
    padding: 15px;
    box-sizing: border-box;

    row-gap: 15px;
    column-gap: 15px;

    /*background-color: rgba(0, 0, 0, 0.1);*/
    /*border-radius: 45px;*/
}

.wrapper-title-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 60px;
}

.group-elements-title-profile-main h1 {
    font-size: 1.3em;
    font-weight: 900;
}

.group-elements-title-profile-main:has(> *:nth-child(n+2)) {
    justify-content: space-between;
}

.group-elements-title-profile-main:has(> *:nth-child(n+2)) h1 {
    font-size: 1.17em;
}

.group-elements-title-profile-main:has(.status-title-profile-main) {
    padding: 25px;
}

/*.group-elements-title-profile-main:has(.buttons-line-support-block) {*/
/*    padding: 25px;*/
/*}*/

.group-elements-title-profile-main:has(> h1:first-child) {
    padding-left: 45px;
}

.status-title-profile-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 40px;
    margin: 0;
    border-radius: 20px;
    background-color: rgba(184, 184, 184, 0.1);
    border: solid 1px #A9A9A9;
    color: #A9A9A9;

    font-size: 0.75em;
    font-weight: 600;

    padding: 0 30px;
    box-sizing: border-box;
}

.content-profile-block-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    width: 100%;
    height: auto;
    padding: 15px;
    box-sizing: border-box;

    font-size: 1em;
    font-weight: 600;

    /*background-color: rgba(0, 0, 0, 0.1);*/
    /*border-radius: 45px;*/
}

.content-profile-block-main h1{
    font-size: 1.17em;
    font-weight: 900;
}

.button-user-console-main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 60px;
    background-color: #005AE6;
    color: white;

    border: none;
    border-radius: 30px;
    padding: 0 30px;
    box-sizing: border-box;

    font-family: Montserrat, serif;
    font-size: 1em;
    font-weight: 600;

    gap: 25px;
}

.button-user-console-main:hover {
    background-color: #004EC6;
}

.button-user-console-main:active {
    background-color: #0048B8;
}

/*Для кнопок с прогресс баром---*/
.button-progress-user-console-main {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: width 0.04s linear;
}

.button-text-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-text-main {
    position: absolute;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.hold-text {
    font-size: 0.6em;
    color: rgba(255, 255, 255, 0.7);
}

.fade-main {
    opacity: 1;
    transform: translateY(0);
}

.fade-up {
    opacity: 0;
    transform: translateY(-5px);
}

.fade-down {
    opacity: 0;
    transform: translateY(5px);
}

.red-point-console-main {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #E40000;
}


/*Плашка профиля -----------------------------------------------------*/
.account-info-block {
    flex-direction: row;
    width: auto;
    height: 120px;
    border-radius: 60px;
    /*flex-grow: 1;*/
    padding: 0 20px 0 70px;
    gap: 30px;
}

.account-info-block img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: solid 2px white;
    box-sizing: border-box;
}

.info-list-account-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    font-size: 1em;

    font-weight: 500;
    gap: 6px;
}

.info-list-account-info div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.info-list-account-info .red-point-console-main {
    width: 12px;
    height: 12px;
}

.info-list-account-info button {
    background-color: transparent;
    color: #242F40;
    border: none;
    font-size: 0.6em;
    font-weight: 500;
    text-decoration: underline;
    border-radius: 10px;
    padding: 2px 10px;
    margin-right: -10px;
    cursor: pointer;
}

.info-list-account-info button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.info-list-account-info button:active {
    background-color: rgba(0, 0, 0, 0.15);
}

/*Блок реферальной программы-----------------------------------------------------*/
.referral-block-user-console {
}

.signature-referral {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 60px;
    border-radius: 30px;
    border: solid 2px rgba(36, 47, 64, 0.7);

    padding: 0 50px;
    box-sizing: border-box;

}

.signature-referral h1 {
    margin-right: -10px;
    margin-left: 10px;
    font-size: 1em;
    font-weight: 900;
}


.signature-referral .red-point-console-main {
    position: absolute;
    inset: 0 auto 0 22.5px;
    margin: auto;
}

/*Продажный блок-----------------------------------------------------*/
.sale-block-user-console {
    gap: 15px;
}

.sale-block-user-console button {
    width: 100%;
    font-weight: 900;
}

.content-block-sale-block {
    gap: 30px;
}

.content-section-sale-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.content-section-sale-block, .content-section-row-sale-block {
    width: 100%;
    padding: 0 75px;
    box-sizing: border-box;
}

.content-section-row-sale-block {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    row-gap: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.cost-description-sale-block {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 5px 40px;
    box-sizing: content-box;
    margin: 0 0 0 -40px;

    border: solid 2px #005AE6;
    border-radius: 30px;
}

.cost-description-sale-block .cost {
    text-align: end;
    font-size: 1.5em;
    font-weight: 900;
}

.cost-benefits-sale-block {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 15px;
}

.promo-form-sale-block {
    position: relative;
    flex-grow: 1;
}

.promo-input-sale-block {
    width: 100%;
    height: 60px;
    border-radius: 30px;
    padding: 0 160px 0 20px;
    box-sizing: border-box;

    background-color: white;
    border: solid 1px #005AE6;
    box-shadow: #005AE644 0 0 10px;

    font-size: 1.17em;
    font-weight: 600;
    color: #005AE6;

    transition: 0.2s ease-in-out;
}

.promo-input-sale-block.ok {
    border-color: #12A227;
    color: #12A22788;
    box-shadow: #12A22744 0 0 10px;
}

.promo-button-sale-block {
    position: absolute;
    inset: 0 10px 0 auto;
    width: 140px !important;
    height: 40px;

    margin: auto;
    padding: 0 20px;

    font-weight: 600 !important;
    font-size: 0.85em;

    transition: 0.2s ease-in-out;
}

.promo-button-sale-block:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.promo-button-sale-block.ok {
    background-color: #12A227;

}

.promo-sign-sale-block {
    position: absolute;
    inset: auto 0 -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 30px;

    font-size: 0.85em;
    color: #E40000;
}

.promo-sign-sale-block.ok {
    color: #49BD5A;
}

/*Подписка отменена----------------------------------------------------*/
.cancelled-description-user-console {
    gap: 15px;
}

.cancelled-description-user-console .status-title-profile-main {
    background-color: rgba(255, 183, 0, 0.1);
    border: solid 1px #E4A400;
    color: #E4A400;
    box-shadow: #E4A40044 0 0 10px;
}

.cancelled-description-user-console button {
    font-weight: 900;
    flex-grow: 1;
}

.content-block-cancelled-description {
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    gap: 60px;
}

.cost-description-cancelled-description {
    width: auto;
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 5px 30px;
    box-sizing: content-box;
    margin: 0;
    flex-grow: 1;

    border: solid 2px #005AE6;
    border-radius: 30px;
}

.cost-description-cancelled-description h1 {
    font-size: 1em;
}

.cost-description-cancelled-description .cost {
    text-align: end;
    font-size: 1.5em;
    font-weight: 900;
}

/*Подписка активна----------------------------------------------------*/
.active-description-user-console {
}

.active-description-user-console .status-title-profile-main {
    background-color: rgba(73, 230, 0, 0.1);
    border: solid 1px #2A8500;
    color: #2A8500;
    box-shadow: #2A850044 0 0 10px;
}

.content-block-active-description {
    align-items: start;
    padding: 20px 45px 30px;
    gap: 5px;
}

.first-pay-active-description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;

    font-size: 1em;
    font-weight: 800;
}

.first-pay-active-description .status-title-profile-main {
    background-color: transparent;
    border: solid 2px rgba(36, 47, 64, 0.7);
    box-shadow: none;
    color: #242F40;
    padding: 0 20px;
    font-size: 1em;
    font-weight: 800;
}

.cancel-description-active-description {
    height: 30px;
    border-radius: 15px;
    padding: 0 15px;
    transform: translate(-15px, 0);

    background-color: transparent;
    color: #242F40;
    border: none;
    font-size: 0.75em;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.cancel-description-active-description:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.cancel-description-active-description:active {
    background-color: rgba(0, 0, 0, 0.15);
}

/*Блок с устройствами----------------------------------------------------*/
.devices-user-console {
}

.devices-user-console .status-title-profile-main {
}

.content-block-devices {
    gap: 15px;
}

.content-block-devices:empty {
    display: none;
}

.device-unit-devices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    height: 60px;
    border-radius: 30px;

    row-gap: 10px;
}

.device-logo-devices {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 30px;
}

.device-logo-devices img {
    position: absolute;
    inset: 0;
    margin: auto;
}

.device-info-devices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    width: auto;
    height: 60px;
    border-radius: 30px;
    padding: 0 30px 0 15px;
    box-sizing: border-box;

    flex-grow: 1;
}

.name-data-string-devices{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    width: 100%;

}

.title-device-devices {
    font-size: 1em;
    font-weight: 800;
}

.text-device-devices {
    font-size: 0.75em;
    font-weight: 600;
}

.device-detach-devices {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 300px;
    height: 60px;
    border-radius: 30px;
}

.button-detach-devices {
    width: 100%;
    height: 100%;
}

.separator-devices {
    width: calc(100% - 60px);
    height: 1px;
    border: none;
    background-color: rgba(36, 47, 64, 0.3);
    margin: 0;
}

/*Скачать приложение----------------------------------------------------*/
.product-app-user-console {
}

.content-block-product-app {
    padding: 15px 15px 30px;
    gap: 15px;
}

.buttons-line-product-app {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    gap: 20px;
    row-gap: 10px;
}

.button-download-app-product-app {
    flex-grow: 1;
}

.button-download-app-product-app svg {
    max-height: 40%;
}

.red-point-console-product-app {
    position: absolute;
    inset: 0 22.5px 0 auto;
    margin: auto;
}

.marker-product-app {
    height: 30px;
    width: calc(100% - 30px);
    padding: 0 15px;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 30px;

    font-size: 1em;
    font-weight: 600;

    box-sizing: border-box;
    border-radius: 15px;
    border: solid 2px transparent;

    transition: border 0.3s ease-in-out;
}

.marker-product-app.highlight {
    border: solid 1px #E40000;
}

/*Поддержка----------------------------------------------------*/
.support-block-user-console {
}

.buttons-line-support-block {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    padding: 7px;
    box-sizing: border-box;

    gap: 20px;
}

.button-support-support-block {
    height: 46px;
    padding: 0 50px;
    border-radius: 25px;
}

/*Отмена подписки----------------------------------------------------*/
.cancel-description-user-console {

}

.content-block-cancel-description {
    padding: 30px 30px;
    gap: 30px;
}

.text-condolences-cancel-description {
    /*text-align: center;*/
}

.cancel-button-cancel-description {
    height: 46px;
    max-width: 400px;
    width: 100%;
    padding: 0 50px;
    border-radius: 25px;
}
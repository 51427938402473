.title-promo-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;

    width: 100%;
    padding: 0 0 0 80px;
    box-sizing: border-box;

    font-size: 2em;
    font-weight: 900;
    color: #005AE6;
    text-align: left;
}

.section-step-promo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 20px;
}

.number-step-promo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    border-radius: 30px;

    background-color: #005AE6;
    color: white;

    font-size: 1.5em;
    font-weight: 900;

    flex-shrink: 0;
}

.description-step-promo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: auto;
    min-height: 60px;
    height: auto;
    border-radius: 30px;

    padding: 15px 30px;
    box-sizing: border-box;

    background-color: rgba(255, 255, 255, 0.7);
    border: solid 1px white;

    font-size: 1em;
    font-weight: 600;

    flex-grow: 1;
}

.info-promo-page {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 70%;
    height: 250px;
    gap: 20px;

    background-color: rgba(255, 255, 255, 0.7);
    border: solid 1px white;
    border-radius: 30px;
    padding: 15px;
    box-sizing: border-box;
}

.QR-info-promo-page {
    width: auto;
    height: 100%;
    aspect-ratio: 1 / 1;

    display: flex;
    justify-content: center;
    align-items: center;
}

.QR-info-promo-page canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px
}

.buttons-info-promo-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: auto;
    height: 100%;
    gap: 20px;

    flex-grow: 1;
}

.buttons-info-promo-page button {
    width: 100%;

    border-radius: 15px;

    flex-grow: 1;
}
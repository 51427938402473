.page-block {
    display: block;
    max-width: 1100px;
    width: 90%;
    margin: auto;

    /*border: solid 1px red;*/
}

.registration-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.registration-block h2 {
    font-size: 28px;
}

#header-site {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 70px;

    /*border: solid 1px red;*/
}

#logo-site {
    width: 60px;
}

#title-site {
    font-weight: bold;
    font-size: 60px;
    margin: 0;
}

#description-site {
    text-align: center;
    font-size: 20px;
}

.content {
    position: relative;
    display: flex;
    justify-content: space-between;

    /*border: solid 1px red;*/
}

#description {
    text-align: left;
    width: 45%;
    /*max-width: 450px;*/

    /*border: solid 1px green;*/
}

.description-element {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.description-element:not(:last-child) {
    margin-bottom: 30px;
}

.title-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.title-description h1 {
    width: calc(100% - 40px);
    margin: 0;
    font-size: 30px;
    font-weight: bold;
}

.description-text {
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

.description-text p {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-top: 10px;
    width: 90%;

    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.075);
    backdrop-filter: blur(10px);
}

.icon {
    transition: transform 0.5s ease;
}

.icon.rotated {
    transform: rotate(90deg);
}


#sale-block {
    width: 50%;
    height: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;

    /*border: solid 1px green;*/
}

#sale-block h2 {
    margin: 0 0 20px;
    font-size: 24px;
}

#sale-block span {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
}

.sale-block-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 450px;
}

.sale-block-content-mobile {
    display: none;
}

.sale-block-button_pay, .sale-block-button_enter {
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    transition: 0.3s;
    border: solid 2px transparent;
    box-sizing: border-box;
}

.sale-block-button_pay {
    height: 60px;
    background-color: #e0ff61;
    font-size: 20px;
    font-weight: 700;
    color: #090A0F;
}

.sale-block-button_pay:hover {
    background-color: #090A0F;
    color: #e0ff61;
    border: solid 2px #e0ff61;
}

.sale-block-button_enter {
    height: 30px;
    background-color: #FFFFFF22;
    color: #FFFFFFAA;
    backdrop-filter: blur(10px);
    font-size: 14px;
    font-weight: 400;
}

.sale-block-button_enter:hover {
    background-color: #FFFFFF44;
    color: #FFFFFFFF;
}

.line {
    width: 40%;
    height: 1px;
    border: 0;
    background: linear-gradient(to right, rgba(256, 256, 256, 0) 0%, rgba(256, 256, 256, 1) 50%, rgba(256, 256, 256, 1) 100%);
}



.description-site-1 {
    text-align: center;
    margin-left: 10px;
    font-size: 22px;
    font-weight: 600;
    color: white;
}


@media (max-width: 850px) {
    .page-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 450px;
    }

    #header-site {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    #logo-site {
        width: 40px;
    }

    #title-site {
        font-size: 46px;
    }







    .content {
        flex-direction: column;
        align-items: center;
    }

    #description {
        width: 100%;
        margin-left: 0;
    }

    .description-element {
    }

    .description-element:not(:last-child) {
    }

    .description-element p {
        font-size: 14px;
    }

    .title-description {
    }

    .title-description h1 {
        font-size: 28px;
    }

    .description-text {
    }

    .description-text.active {
    }

    .icon {
    }

    .icon.rotated {
    }





    #sale-block {
        width: 100%;
        height: auto;
        min-height: 0;
        margin: 70px 0 0;

    }

    #sale-block h2 {
        font-size: 24px;
    }

    #sale-block span {
        font-size: 20px;
    }

    .sale-block-content-mobile {
        margin-bottom: 50px;
        display: flex;
    }

    .sale-block-button_enter-desctop {
        display: none;
    }


    .line {
    }
}

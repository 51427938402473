/*.primal_container {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    position: relative;*/
/*    top: -60%;*/
/*    width: 100%;*/
/*}*/

/*@media (max-width: 740px) {*/
/*    .primal_container {*/
/*        top: -20%;*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*.svg1, .svg2, .svg3, .svg4 {*/
/*    position: absolute; !* Use absolute positioning to overlap *!*/
/*    width: 60vw;*/
/*    height: 60vw;*/
/*    opacity: 0.07;*/

/*    !*animation: rotate 1s linear infinite;*!*/
/*}*/

/*.svg1 {*/
/*    animation: rotate 275s linear infinite;*/
/*}*/

/*.svg2 {*/
/*    animation: rotate-1 350s linear infinite;*/
/*}*/

/*.svg3 {*/
/*    animation: rotate 400s linear infinite;*/
/*}*/

/*.svg4 {*/
/*    animation: rotate-1 310s linear infinite;*/
/*}*/


@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-1 {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.Orbits_container{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.Orbits {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin: auto;

    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    width: 1000px;
    height: 0;

    /*border: solid 1px green;*/
}

.orbit {
    position: absolute;
    top: -150px; bottom: 0;
    right: 0; left: 0;
    margin: auto;

    opacity: 0.07;
}

.orbit-1 {
    width: 100%;
    animation: rotate 275s linear infinite;
}

.orbit-2 {
    width: 83%;
    animation: rotate-1 350s linear infinite;
}

.orbit-3 {
    width: 75%;
    animation: rotate 400s linear infinite;
}

.orbit-4 {
    width: 55%;

    animation: rotate-1 310s linear infinite;
}

@media (max-width: 850px) {
    .Orbits {
        width: 700px;
    }

    .orbit {
        top: -30px; bottom: 0;
        right: 0; left: 0;
    }
}

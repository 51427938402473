.row-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.card {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-header {
    text-align: center;
    padding: 20px;
    background-color: #f0f4f7;
    color: #333;
}

.card-promotion {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-style: italic;
    background-color: #f0f4f7;
    color: #333;
}

.card-promotion-complete {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-style: italic;
    background-color: #f0f4f7;
    color: #333;
}

.card-promotion:hover {
    background-color: #E6EBEE;
    cursor: pointer;
}

.card-promotion:focus {
    background-color: #E6EBEE;
    outline: none;
}

.card-input {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-style: italic;
    background-color: #E6EBEE;
    color: #333;
}

.promo-input {
    background-color: #D4DADD;
    color: #333333;
    border: none;
    font-style: italic;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    outline: none;
    width: 30%;
    caret-color: #333333;
    caret-shape: underscore;
}


.discount-badge {
    padding: 5px;
    border-radius: 10px;
    font-size: 22px;
    font-weight: 600;
}

.card-title {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 500;
}

.price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.price-value {
    font-size: 26px;
}

.price-symbol {
    font-size: 18px;
}

.price-old {
    text-decoration: line-through;
    color: darkgray;
    font-size: 14px;
}

.card-body {
    padding: 10px;
    background-color: #f9fafb;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature {
    font-size: 20px;
    font-weight: 300;
}

@media (max-width: 500px) {
    .feature {
        font-size: 18px;
    }
}

.feature:not(:last-child) {
    /*margin-bottom: 200px;*/
}

.card-body table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px 0;
}

.card-body td {
}

.icon {
    width: 20px;
}

.go-icon {
    display: flex;
    justify-content: center;
    color: #333333;
    width: 6%;
    min-width: 10px;
    cursor: pointer;
}

.input-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

td, th, table {
    /*border: 2px solid red;*/
}
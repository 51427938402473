.container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 90%;
    margin: auto;

    /*border: solid 1px red;*/
}

/*@media (max-width: 800px) {*/
/*    .container-main {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        align-items: center !important;*/
/*    }*/
/*}*/
/*@media (max-width: 850px) {*/
/*    .container-main {*/
/*        max-width: 95%;*/
/*        margin-left: 20px;*/
/*    }*/
/*}*/



.button_dark {
    display: block;
    width: 90%;
    max-width: 700px;
    background-color: #111821;
    color: white;
    padding: 12px;
    border-radius: 12px;
    margin: 8px;
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgba(22, 31, 43, 0.43);
    border: none;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.button_dark:hover {
    background-color: #161F2B;
}

.button_dark:focus {
    background-color: #1A2533;
    outline: none;
}

.custom-toast {
    color: white;
    background-color: #333333;
}




.App-download {
    cursor: pointer;
    color: #222222;
    transition: 0.3s
}

.App-download:hover {
    color: #555555;
}

.full-line-black {
    width: 90%;
    max-width: 700px;
    height: 1px;
    border: 0;
    margin: 15px 0 0 0;
    color: #999999;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
}

.device-box {
    position: relative;
    color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 100%;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    transition: 0.3s;
}

.device-box-button {
    padding-bottom: 30px;
    cursor: pointer;
}

.block-progress-signature {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    color: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
}

.device-box-button:hover .block-progress-signature {
    background: linear-gradient(150deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.5) 100%);
    background-size: 300% auto;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradientMove 1s linear infinite;
}

.device-attetion {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 10px;

    padding: 5px;
    margin-left: -5px;

    border: solid 1px transparent;
    border-radius: 10px;

    transition: 0.3s;
}


@keyframes gradientMove {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.progress-bar {
    transition: width 0.1s linear;
}

.device-flex {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.device-icon {
    display: flex;
    justify-content: center;
    color: #222222;
    width: 6%;
    min-width: 50px;
    margin: 0 15px 0 20px;
}

.device-icon-cross {
    display: flex;
    justify-content: center;
    color: #222222;
    width: 6%;
    min-width: 30px;
    margin: 0 15px 0 15px;
    cursor: pointer;
}

.device-icon h2 {
    margin: 0;
    font-size: 28px;
}

.device-info {
    width: 85%;
}

.device-title {
    font-weight: bold;
    font-size: 20px;
}

.device-subtitle {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

.device-description {
    margin-right: 15px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.device-subtext {
    color: #222222;
    font-size: 14px;
    transition: 0.3s;
}

.qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
}

.qr-code canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px
}

.button-text:hover {
    text-decoration: underline;
}

@media (max-width: 550px) {
    .device-title {
        font-size: 18px;
    }

    .device-subtitle {
        font-size: 16px;
    }

    .device-subtext {
        font-size: 12px;
    }
}

@media (max-width: 500px) {
    .flex-change {
        flex-direction: column;
    }

    .qr-code {
        width: 80%;
        height: 80%;
    }
}





@media (max-width: 999px) {
    .title-promo-page {
        padding: 0;
        font-size: 1.5em;
    }

    .section-step-promo {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 10px;
    }

    .number-step-promo {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        font-size: 1.2em;
    }

    .description-step-promo {
        min-height: 40px;
        border-radius: 20px;
        padding: 10px 20px;
        font-size: 0.85em;
    }

    .info-promo-page {
        flex-direction: column;
        justify-content: start;

        width: 250px;
        height: auto;

        border-radius: 20px;
        padding: 5px;
    }

    .QR-info-promo-page {
        height: auto;
        width: 100%;
    }

    .QR-info-promo-page canvas {
    }

    .buttons-info-promo-page {
        width: 100%;
        height: auto;
        gap: 10px;
    }

    .buttons-info-promo-page button {
    }
}
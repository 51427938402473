.Promo-block-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: calc(100% - 2 * (30px + 10px));
    margin: auto;
}

.Promo-block {
    width: 100%;
    position: relative;
    font-size: 18px;

    /*border: solid 1px red;*/
}

.Promo-block-title {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    padding: 0 0 70px;
    box-sizing: border-box;
    text-shadow:
            0 0 5px #FF00AA,
            0 0 10px #DC0092,
            0 0 30px #910060,
            0 0 40px #910060;
}

.Promo-block-info {
    padding: 70px 50px 0;
    box-sizing: border-box;
}

.Promo-block-instruction {
    min-height: calc(2*(40px + 2px) + 60px + 2 * 15px); /*последнее значение - отступы от кругов*/
    padding: 50px 50px; /*за счет padding у текста тут будет +10px*/
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Promo-block-number {
    position: absolute;
    inset: 0 -30px;
    margin: auto;

    width: 60px;
    height: 60px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    border: solid 3px white;
    box-sizing: border-box;
    background-color: transparent;
    box-shadow:
            0 0 5px #FF00AA,
            0 0 10px #DC0092,
            0 0 30px #910060,
            0 0 40px #910060,
            inset 0 0 5px #FF00AA,
            inset 0 0 10px #DC0092,
            inset 0 0 30px #910060,
            inset 0 0 40px #910060;
}

.Promo-block-number span {
    font-size: 26px;
    font-weight: 600;
}

.Promo-block-number-left {
   right: auto !important;
}

.Promo-block-number-right {
    left: auto !important;
}

.Promo-block-info-card {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(10px);
    font-weight: 500;
}

.Promo-block-info-card-left {
    background:
            rgba(255, 255, 255, 0.05)
            linear-gradient(to right, #FF00AA22, #FF00AA00);
    backdrop-filter: blur(10px);
}

.Promo-block-info-card-right {
    background:
            rgba(255, 255, 255, 0.05)
            linear-gradient(to left, #FF00AA22, #FF00AA00);
    backdrop-filter: blur(10px);
}

.Promo-block-arrow {
    position: absolute;
    inset: -2px;
    width: calc(50% - 2px);
    min-height: 40px;
    height: calc((100% - 60px - (15px * 2)) / 2 - 2px);
    border: solid 4px white;
    border-radius: 40px;
}

.Promo-block-arrow-half {
    width: calc(25% - 2px);
}

.Promo-block-arrow-I {
    bottom: auto !important;
    left: auto !important;
    border-bottom: none !important;
    border-left: none !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.Promo-block-arrow-II {
    top: auto !important;
    left: auto !important;
    border-top: none !important;
    border-left: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.Promo-block-arrow-III {
    top: auto !important;
    right: auto !important;
    border-top: none !important;
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.Promo-block-arrow-IV {
    bottom: auto !important;
    right: auto !important;
    border-bottom: none !important;
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.Promo-block-share {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    padding: 20px;
    gap: 20px;

    border: solid 3px white;
    border-radius: 30px;
    box-sizing: border-box;
    background-color: transparent;
    box-shadow:
            0 0 5px #FF00AAAA,
            0 0 10px #DC0092AA,
            0 0 20px #910060AA,
            0 0 40px #910060AA,
            inset 0 0 5px #FF00AAAA,
            inset 0 0 10px #DC0092AA,
            inset 0 0 30px #910060AA;
}

.Promo-block-qr-code {
    max-width: 200px;
    /*width: 50%;*/
    aspect-ratio: 1 / 1;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Promo-block-qr-code canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px
}

.Promo-block-buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    flex-grow: 1;
}

.Promo-block-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 50px;
    border-radius: 25px;

    color: white;

    cursor: pointer;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;

    border: solid 3px white;
    box-sizing: border-box;
    background-color: transparent;

    transition: 0.3s;
}

.Promo-block-button:hover {
    background: white;
    color: black;
}

.Promo-block-button:active {
    background: white;
    color: #FF00AA;
}

.Promo-block-button:focus {
    background: white;
    color: #FF00AA;
}

.Promo-block-title-sale {
    position: relative;

    /*border: solid 1px red;*/
}

.Promo-block-title-sale-border {
    position: absolute;
    left: -9px;
    top: -1px;

    width: calc(100% + 10px);
    height: calc(100% + 2px);
    border-radius: 15px;
    rotate: -3deg;

    border: dotted 3px white;
}

@media (max-width: 650px) {
    .Promo-block-info {
        max-width: 300px;
        padding: 70px 25px 0;
    }

    .Promo-block-share {
        flex-direction: column;
        gap: 30px;
    }

    .Promo-block-qr-code {
        max-width: none;
        width: 100%;
    }

    .Promo-block-arrow-half {
        width: calc(25% - 2px);
    }

    .Promo-block-buttons{
        justify-content: center;
        gap: 20px;
    }
}

@media (max-width: 550px) {
    .Promo-block {
        font-size: 14px;
    }

    .Promo-block-title {
        font-size: 24px;
    }

    .Promo-block-instruction {
        min-height: calc(2*(40px + 2px) + 50px + 2 * 15px); /*последнее значение - отступы от кругов*/
        padding: 40px 40px; /*за счет padding у текста тут будет +10px*/
    }

    .Promo-block-number {
        inset: 0 -25px;
        width: 50px;
        height: 50px;
    }

    .Promo-block-number span {
        font-size: 20px;
    }

    .Promo-block-arrow {
        height: calc((100% - 50px - (15px * 2)) / 2 - 2px);
    }

    .Promo-block-button {
        font-size: 14px;
    }
}




